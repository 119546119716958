import { Element } from 'react-scroll';

import Heading from './heading';
import Panel from './panel';

import { StyledAccordion } from './accordion.style';

export const Accordion = ({
  name,
  title,
  buttons,
  component = false,
  children
}) => {
  return (
    <StyledAccordion>
      <Element name={name}>
        {title && (
          <Heading title={title} buttons={buttons} component={component} />
        )}
        {children.map(({ node }, i) => (
          <Panel key={node.title} index={i} item={node} />
        ))}
      </Element>
    </StyledAccordion>
  );
};

export default Accordion;
