import { useState } from 'react';
import cx from 'classnames';

import {
  StyledPanel,
  PanelLabel,
  PanelInner,
  PanelContent
} from './accordion.style';

const Panel = ({ item }) => {
  const [isActiveTab, setIsActiveTab] = useState(false);
  const {
    frontmatter: { title },
    html
  } = item;

  const activateTab = () => {
    setIsActiveTab((prev) => !prev);
  };

  return (
    <StyledPanel className={cx({ open: isActiveTab })}>
      <PanelLabel onClick={activateTab}>
        <h4>{title}</h4> <span className="label-icon" />
      </PanelLabel>
      <PanelInner>
        <PanelContent dangerouslySetInnerHTML={{ __html: html }} />
      </PanelInner>
    </StyledPanel>
  );
};

export default Panel;
