import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/common/button/button';
import { StyledListItem } from './accordion.style';

const ButtonLink = ({ name, title }) => {
  const { t } = useTranslation();

  return (
    <StyledListItem key={name}>
      <Link smooth to={name} offset={-40} duration={500}>
        <Button variant="outlined">{t(title)}</Button>
      </Link>
    </StyledListItem>
  );
};

export default ButtonLink;
