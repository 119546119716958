import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledAccordion = styled.div`
  &:not(:last-child) {
    margin-bottom: 60px;
  }
`;

export const StyledHeading = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 70px;

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  h2 {
    padding-top: 10px;

    @media (min-width: 576px) {
      margin-right: 20px;
    }

    @media (max-width: 575px) {
      margin-bottom: 20px;
    }
  }
`;

export const StyledButtonsList = styled.ul`
  display: flex;
  list-style: none;
  padding-top: 10px;

  @media (max-width: 575px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const StyledListItem = styled.li`
  @media (min-width: 576px) {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @media (max-width: 575px) {
    button {
      width: 100%;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

export const ComponentHeadingWrapper = styled.div`
  margin-left: 30px;
  width: 320px;
  padding-top: 10px;

  @media (max-width: 575px) {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
`;

export const PanelLabel = styled.div`
  width: 100%;
  background: none;
  border: none;
  font-weight: ${themeGet('fontWeights.semi')};
  color: ${themeGet('colors.text.default')};
  transition: color 0.25s linear;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    font-size: 24px;
    line-height: 1.4;

    @media ${themeGet('mediaQueries.mobileOnly')} {
      font-size: 16px;
    }
  }

  &:focus {
    outline: none;
  }

  .label-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-left: 35px;
    width: 60px;
    height: 60px;
    background-color: ${themeGet('colors.button.variant.gray.regular')};
    border-radius: ${themeGet('radii.base')};

    &:after,
    &:before {
      position: absolute;
      width: 18px;
      height: 2px;
      background-color: ${themeGet('colors.text.light')};
      content: '';
    }

    &:before {
      transform: rotate(-90deg);
      transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1); */
    }
  }
`;

export const PanelInner = styled.div`
  overflow: hidden;
  will-change: max-height;
  max-height: 0;
  transition: max-height 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
`;

export const StyledPanel = styled.div`
  position: relative;
  padding: 12px 20px;
  background-color: ${themeGet('colors.body.bg')};
  box-shadow: ${themeGet('shadows.section')};
  border-radius: ${themeGet('radii.base')};

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &.open {
    ${PanelLabel} {
      color: ${themeGet('colors.primary.regular')};

      .label-icon {
        &:before {
          transform: rotate(0deg);
        }
      }
    }

    ${PanelInner} {
      max-height: 1000px;
    }
  }
`;

export const PanelContent = styled.div`
  padding-bottom: 10px;
  padding-top: 10px;

  @media ${themeGet('mediaQueries.tabletPortraitUp')} {
    padding-right: 120px;
  }

  ul,
  ol {
    padding-left: 40px;
    @media ${themeGet('mediaQueries.mobileOnly')} {
      padding-left: 20px;
    }
  }

  ol > li {
    list-style: decimal;
  }
  ul > li {
    list-style: disc;
  }

  p,
  ol,
  ul,
  li,
  a {
    font-size: 24px;
    line-height: 1.4;
    font-weight: ${themeGet('fontWeights.light')};

    @media ${themeGet('mediaQueries.mobileOnly')} {
      font-size: 16px;
    }
  }
`;
