import ButtonLink from './button';

import {
  StyledHeading,
  StyledButtonsList,
  ComponentHeadingWrapper
} from './accordion.style';

const Heading = ({ title, buttons = [], component }) => {
  return (
    <StyledHeading>
      <h2>{title}</h2>
      {buttons.length > 0 && (
        <StyledButtonsList>{buttons.map(ButtonLink)}</StyledButtonsList>
      )}
      {/* {component && ( */}
      {/*  <ComponentHeadingWrapper>{component}</ComponentHeadingWrapper> */}
      {/* )} */}
    </StyledHeading>
  );
};

export default Heading;
