import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCoins } from 'contexts/coins.context';
import { graphql } from 'gatsby';

import SEO from 'components/seo';
import { Accordion } from 'components/common/accordion/accordion';
import CoinToggler from 'components/common/coin-toggler/coin-toggler';
import { useTheme } from 'contexts/theme.context';

import { MainContentArea, Container } from 'assets/styles/pages.style';

export default function FaqPage({ data }) {
  const { t } = useTranslation();
  const { language } = useTheme();
  const { generalSorted, miningSortedETH, miningSortedETC, payoutsSorted } =
    data;
  const { coins, loading } = useCoins();
  const [activeCoin, setActiveCoin] = useState(null);

  useEffect(() => {
    if (!loading && coins.length > 0) {
      setActiveCoin(coins[0]);
    }
  }, [coins, loading]);

  return (
    <MainContentArea>
      <Container>
        <Accordion
          key="general"
          name="general"
          title={t('pageTitles.faq.title')}
          buttons={[
            {
              name: 'mining',
              title: 'pageFaq.mining'
            },
            {
              name: 'payouts',
              title: 'pageFaq.payouts'
            }
          ]}
        >
          {generalSorted.edges.filter(
            ({ node }) =>
              node.frontmatter.lng.toLowerCase() === language.toLowerCase()
          )}
        </Accordion>

        <Accordion
          key="mining"
          name="mining"
          title={t('pageFaq.mining')}
          buttons={[
            {
              name: 'general',
              title: 'pageFaq.general'
            },
            {
              name: 'payouts',
              title: 'pageFaq.payouts'
            }
          ]}
          component={
            <CoinToggler state={[activeCoin, setActiveCoin]} width="100%" />
          }
        >
          {activeCoin?.tag === 'eth'
            ? miningSortedETH.edges.filter(
                ({ node }) =>
                  node.frontmatter.lng.toLowerCase() === language.toLowerCase()
              )
            : miningSortedETC.edges.filter(
                ({ node }) =>
                  node.frontmatter.lng.toLowerCase() === language.toLowerCase()
              )}
        </Accordion>

        <Accordion
          key="payouts"
          name="payouts"
          title={t('pageFaq.payouts')}
          buttons={[
            {
              name: 'general',
              title: 'pageFaq.general'
            },
            {
              name: 'mining',
              title: 'pageFaq.mining'
            }
          ]}
        >
          {payoutsSorted.edges.filter(
            ({ node }) =>
              node.frontmatter.lng.toLowerCase() === language.toLowerCase()
          )}
        </Accordion>
      </Container>
    </MainContentArea>
  );
}

export const Head = () => {
  const { t } = useTranslation();
  return <SEO title={t('pageTitles.faq.title')} />;
};

export const FAQ_QUERY = graphql`
  query faqQuery {
    generalSorted: allMarkdownRemark(
      limit: 200
      filter: { frontmatter: { category: { eq: "general" } } }
      sort: { fields: frontmatter___sort }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            category
            sort
            lng
          }
        }
      }
    }
    miningSortedETH: allMarkdownRemark(
      limit: 200
      filter: { frontmatter: { category: { eq: "miningETH" } } }
      sort: { fields: frontmatter___sort }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            category
            sort
            lng
          }
        }
      }
    }
    miningSortedETC: allMarkdownRemark(
      limit: 200
      filter: { frontmatter: { category: { eq: "miningETC" } } }
      sort: { fields: frontmatter___sort }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            category
            sort
            lng
          }
        }
      }
    }
    payoutsSorted: allMarkdownRemark(
      limit: 200
      filter: { frontmatter: { category: { eq: "payouts" } } }
      sort: { fields: frontmatter___sort }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            category
            sort
            lng
          }
        }
      }
    }
  }
`;
